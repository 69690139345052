import React from "react";
import classNames from "classnames/bind";
import { Col, Form } from "react-bootstrap";
import { injectIntl } from 'gatsby-plugin-intl';

import Button from "../Button";

import styles from "./index.module.scss";

let classNamesBound = classNames.bind(styles);

class SendMoney extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeCurrency: "US"
    };
  }

  onSelectChange(event) {
    this.setState({ activeCurrency: event.target.value });
  }

  render() {
    const intl = this.props.intl;
    
    let formSendMoneySelectClasses = classNamesBound({
      formSendMoneySelect: true,
      [`${this.state.activeCurrency}`]: this.state.activeCurrency
    });

    return (
      <Col xs={12} lg={6} className={styles.sendMoneyWrapper}>
        <div className={styles.sendMoney}>
          <h1>{intl.formatMessage({id: 'send-money.title'})}</h1>
          <h2>{intl.formatMessage({id: 'send-money.text'})}</h2>
          <Form>
            <Form.Label>{intl.formatMessage({id: 'send-money.form.label'})}</Form.Label>
            <Form.Control
              as="select"
              defaultValue={this.state.activeCurrency}
              className={formSendMoneySelectClasses}
              onChange={event => this.onSelectChange(event)}
            >
              <option className={styles.AS} value="AS">
                American Samoa
              </option>
              <option className={styles.AD} value="AD">
                Andorra
              </option>
              <option className={styles.AR} value="AR">
                Argentina
              </option>
              <option className={styles.AU} value="AU">
                Australia
              </option>
              <option className={styles.AT} value="AT">
                Austria
              </option>
              <option className={styles.BE} value="BE">
                Belgium
              </option>
              <option className={styles.BV} value="BV">
                Bouvet Island
              </option>
              <option className={styles.BR} value="BR">
                Brazil
              </option>
              <option className={styles.IO} value="IO">
                British Indian Ocean Territory
              </option>
              <option className={styles.CA} value="CA">
                Canada
              </option>
              <option className={styles.CL} value="CL">
                Chile
              </option>
              <option className={styles.CN} value="CN">
                China
              </option>
              <option className={styles.CX} value="CX">
                Christmas Island
              </option>
              <option className={styles.CC} value="CC">
                Cocos (Keeling) Islands
              </option>
              <option className={styles.CO} value="CO">
                Colombia
              </option>
              <option className={styles.CK} value="CK">
                Cook Islands
              </option>
              <option className={styles.CY} value="CY">
                Cyprus
              </option>
              <option className={styles.CZ} value="CZ">
                Czech Republic
              </option>
              <option className={styles.DK} value="DK">
                Denmark
              </option>
              <option className={styles.EE} value="EE">
                Estonia
              </option>
              <option className={styles.FO} value="FO">
                Faroe Islands
              </option>
              <option className={styles.FI} value="FI">
                Finland
              </option>
              <option className={styles.FR} value="FR">
                France
              </option>
              <option className={styles.GF} value="GF">
                French Guiana
              </option>
              <option className={styles.TF} value="TF">
                French Southern territories
              </option>
              <option className={styles.DE} value="DE">
                Germany
              </option>
              <option className={styles.GR} value="GR">
                Greece
              </option>
              <option className={styles.GL} value="GL">
                Greenland
              </option>
              <option className={styles.HM} value="HM">
                Heard Island and McDonald Islands
              </option>
              <option className={styles.HK} value="HK">
                Hong Kong
              </option>
              <option className={styles.HU} value="HU">
                Hungary
              </option>
              <option className={styles.IN} value="IN">
                India
              </option>
              <option className={styles.IE} value="IE">
                Ireland
              </option>
              <option className={styles.IL} value="IL">
                Israel
              </option>
              <option className={styles.IT} value="IT">
                Italy
              </option>
              <option className={styles.JP} value="JP">
                Japan
              </option>
              <option className={styles.KI} value="KI">
                Kiribati
              </option>
              <option className={styles.KW} value="KW">
                Kuwait
              </option>
              <option className={styles.LI} value="LI">
                Liechtenstein
              </option>
              <option className={styles.LU} value="LU">
                Luxembourg
              </option>
              <option className={styles.MY} value="MY">
                Malaysia
              </option>
              <option className={styles.MT} value="MT">
                Malta
              </option>
              <option className={styles.MH} value="MH">
                Marshall Islands
              </option>
              <option className={styles.YT} value="YT">
                Mayotte
              </option>
              <option className={styles.MX} value="MX">
                Mexico
              </option>
              <option className={styles.MC} value="MC">
                Monaco
              </option>
              <option className={styles.NR} value="NR">
                Nauru
              </option>
              <option className={styles.NL} value="NL">
                Netherlands
              </option>
              <option className={styles.NZ} value="NZ">
                New Zealand
              </option>
              <option className={styles.NU} value="NU">
                Niue
              </option>
              <option className={styles.NF} value="NF">
                Norfolk Island
              </option>
              <option className={styles.MP} value="MP">
                Northern Mariana Islands
              </option>
              <option className={styles.NO} value="NO">
                Norway
              </option>
              <option className={styles.PW} value="PW">
                Palau
              </option>
              <option className={styles.PE} value="PE">
                Peru
              </option>
              <option className={styles.PH} value="PH">
                Philippines
              </option>
              <option className={styles.PL} value="PL">
                Poland
              </option>
              <option className={styles.PT} value="PT">
                Portugal
              </option>
              <option className={styles.PR} value="PR">
                Puerto Rico
              </option>
              <option className={styles.RU} value="RU">
                Russian Federation
              </option>
              <option className={styles.PM} value="PM">
                Saint Pierre and Miquelon
              </option>
              <option className={styles.SM} value="SM">
                San Marino
              </option>
              <option className={styles.SA} value="SA">
                Saudi Arabia
              </option>
              <option className={styles.SG} value="SG">
                Singapore
              </option>
              <option className={styles.SK} value="SK">
                Slovakia
              </option>
              <option className={styles.SI} value="SI">
                Slovenia
              </option>
              <option className={styles.GS} value="GS">
                South Georgia and the South Sandwich Islands
              </option>
              <option className={styles.ES} value="ES">
                Spain
              </option>
              <option className={styles.SE} value="SE">
                Sweden
              </option>
              <option className={styles.CH} value="CH">
                Switzerland
              </option>
              <option className={styles.TH} value="TH">
                Thailand
              </option>
              <option className={styles.TK} value="TK">
                Tokelau
              </option>
              <option className={styles.TN} value="TN">
                Tunisia
              </option>
              <option className={styles.TV} value="TV">
                Tuvalu
              </option>
              <option className={styles.AE} value="AE">
                United Arab Emirates
              </option>
              <option className={styles.GB} value="GB">
                United Kingdom
              </option>
              <option className={styles.US} value="US">
                United States
              </option>
              <option className={styles.UY} value="UY">
                Uruguay
              </option>
              <option className={styles.VI} value="VI">
                Virgin Islands, U.S.
              </option>
            </Form.Control>
            <Button className={styles.continueButton} href={intl.formatMessage({id: 'send-money.form.action'})}>{intl.formatMessage({id: 'send-money.form.submit-button.text'})}</Button>
          </Form>
        </div>
      </Col>
    );
  }
}

export default injectIntl(SendMoney);
