import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import _ from "lodash";

import Heading from "../Heading";
import PreviewCompatibleImage from "../PreviewCompatibleImage";

import styles from "./index.module.scss";

class Testimonials extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTestimonial: 0
    };
  }

  componentDidMount() {
    this.preloadImages();
  }

  preloadImages() {
    this.props.testimonials.forEach(testimonial => {
      const img = new Image();
      img.src = this.props.intl.formatMessage({id: `testimonials.${testimonial.id}.imageUrl`});
    });
  }

  previousTestimonial() {
    if (this.state.currentTestimonial === 0)
      this.setState({
        currentTestimonial: this.props.testimonials.length - 1
      });
    else {
      this.setState({
        currentTestimonial: this.state.currentTestimonial - 1
      });
    }
  }

  nextTestimonial() {
    if (this.state.currentTestimonial === this.props.testimonials.length - 1) {
      this.setState({
        currentTestimonial: 0
      });
    } else {
      this.setState({
        currentTestimonial: this.state.currentTestimonial + 1
      });
    }
  }

  render() {
    const intl = this.props.intl;
    const current = this.props.testimonials[this.state.currentTestimonial].id;
    return (
      <Container fluid={true} className={styles.fluidContainer}>
        <Container className={styles.fixedContainer}>
          <Row>
            <Col className={styles.headingColumn}>
              <Heading text={this.props.headingText} themeType={"dark"} />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className={styles.imageWrapper}>
                <PreviewCompatibleImage
                  imageInfo={{
                    image: intl.formatMessage({id: `testimonials.${current}.imageUrl`}),
                    alt: intl.formatMessage({id: `testimonials.${current}.name`})
                  }}
                  roundedCorners={true}
                />
              </div>
              <div className={styles.testimonial}>
                <div className={styles.name}>
                  {intl.formatMessage({id: `testimonials.${current}.name`})}
                </div>
                <div className={styles.titles}>
                  {
                    intl.formatMessage({id: `testimonials.${current}.titles`})
                  }
                </div>
                <blockquote>
                  { intl.formatMessage({id: `testimonials.${current}.quote`}) }
                </blockquote>
                <div className={styles.controls}>
                  <button
                    className={styles.prevControl}
                    onClick={() => this.previousTestimonial()}
                  >
                    Previous Testimonial
                  </button>
                  <div className={styles.pagination}>
                    {_.padStart(this.state.currentTestimonial + 1, 2, "0")}
                    <span className={styles.controlDivider}>{" / "}</span>
                    {_.padStart(this.props.testimonials.length, 2, "0")}
                  </div>
                  <button
                    className={styles.nextControl}
                    onClick={() => this.nextTestimonial()}
                  >
                    Next Testimonial
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    );
  }
}

export default Testimonials;
