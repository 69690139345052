import React from "react";
import { Row, Col } from "react-bootstrap";
import { injectIntl } from 'gatsby-plugin-intl';

import Section from "../Section";
import Button from "../Button";

import styles from "./index.module.scss";

import iconLock from "../../img/icon-lock.svg";

class SecurityStandards extends React.Component {
	render() {
		const intl = this.props.intl;
		return (
			<Section className={styles.securitySection}>
				<Row className={styles.badge}>
					<Col>
						<Row>
							<Col
								xs={12}
								lg={true}
								className={styles.headingColumn}
							>
								<div className={styles.headingWrapper}>
									<img
										src={iconLock}
										className={styles.icon}
										width="65"
										height="65"
										alt=""
									/>
									<h1 className={styles.heading}>
										{intl.formatMessage({id: 'security-standards.title'})}
									</h1>
								</div>
							</Col>
							<Col xs={12} lg={true}>
								<p>
								{intl.formatMessage({id: 'security-standards.text'})}
								</p>
								<Button
									to={"/security"}
									color={"darkPurple"}
									className={styles.button}
								>
									{intl.formatMessage({id: 'security-standards.button.text'})}
								</Button>
							</Col>
						</Row>
					</Col>
				</Row>
			</Section>
		);
	}
}

export default injectIntl(SecurityStandards);
