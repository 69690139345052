import React from "react";
import { graphql } from 'gatsby'
import { injectIntl } from 'gatsby-plugin-intl';

import Layout from "../../components/Layout";
import Background from "../../components/Background";
import Hero from "../../components/Hero";
import MotifColumn from "../../components/MotifColumn";
import SendMoney from "../../components/SendMoney";
import BucketGroup from "../../components/BucketGroup";
import Bucket from "../../components/Bucket";
import SecurityStandards from "../../components/SecurityStandards";
import Testimonials from "../../components/Testimonials";
import Solutions from "../../components/Solutions";
import Questions from "../../components/Questions";

import sheildIcon from '../../img/icon-shield.svg';
import arrowIcon from '../../img/icon-arrow.svg';
import serviceIcon from '../../img/icon-service.svg';

const IndexPage = ({data, intl}) => {
  return (
    <Layout>
      <Background themeType={"light"}>
        <Hero type={"home"} backgroundType={"dark"}>
          <MotifColumn>
            <h1>{intl.formatMessage({id: 'personal.hero.title'})}</h1>
            <p>
            {intl.formatMessage({id: 'personal.hero.text'})}
            </p>
          </MotifColumn>
          <SendMoney />
        </Hero>
          <BucketGroup headingText={intl.formatMessage({id: 'personal.shift.title'})}>
          <Bucket
            imageURL={sheildIcon}
            title={intl.formatMessage({id: 'personal.shift.safety-first.title'})}
            description={
              intl.formatMessage({id: 'personal.shift.safety-first.description'})
            }
          />
          <Bucket
            imageURL={arrowIcon}
            title={intl.formatMessage({id: 'personal.shift.worry-less.title'})}
            description={
              intl.formatMessage({id: 'personal.shift.worry-less.description'})
            }
          />
          <Bucket
            imageURL={serviceIcon}
            title={intl.formatMessage({id: 'personal.shift.at-your-service.title'})}
            description={
              intl.formatMessage({id: 'personal.shift.at-your-service.description'})
            }
          />
        </BucketGroup>
        <SecurityStandards />
        <Testimonials headingText={intl.formatMessage({id: 'personal.testimonials.title'})} testimonials={data.testimonialsYaml.items} intl={intl} />
        <Solutions />
        <Questions />
      </Background>
    </Layout>
  );
};

export default injectIntl(IndexPage);

export const query = graphql`
  query {
    testimonialsYaml(page: {eq: "personal"}) {
      items {
        id
      }
    }
  }
`
