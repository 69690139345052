import React from "react";
import { navigate, injectIntl } from "gatsby-plugin-intl";
import { Container, Row, Col } from "react-bootstrap";

import Heading from "../Heading";

import styles from "./index.module.scss";

import iconService from "../../img/icon-service.svg";
import iconBusiness from "../../img/icon-business.svg";

class Solutions extends React.Component {
	render() {
		const intl = this.props.intl;
		return (
			<Container className={styles.fixedContainer}>
				<Row>
					<Col className={styles.headingColumn}>
						<Heading text={intl.formatMessage({id: 'solutions.title'})} />
					</Col>
				</Row>
				<Row>
					<Col
						sm={12}
						md={true}
						className={styles.solution}
						onClick={() => {
							navigate("/business");
						}}
					>
						<div className={styles.imageWrapper}>
							<img
								src={iconBusiness}
								width="42"
								height="42"
								alt=""
							/>
						</div>
						<div className={styles.contentWrapper}>
							<h2 className={styles.title}>{intl.formatMessage({id: 'solutions.business.title'})}</h2>
							<p>
							{intl.formatMessage({id: 'solutions.business.text'})}
							</p>

							<div className={styles.cta}>
								<span>{intl.formatMessage({id: 'solutions.business.button.text'})}</span>
							</div>
						</div>
					</Col>
					<Col
						sm={12}
						md={true}
						className={styles.solution}
						onClick={() => {
							window.location = intl.formatMessage({id: 'solutions.personal.button.link'})
						}}
					>
						<div className={styles.imageWrapper}>
							<img
								src={iconService}
								width="42"
								height="42"
								alt=""
							/>
						</div>
						<div className={styles.contentWrapper}>
							<h2 className={styles.title}>{intl.formatMessage({id: 'solutions.personal.title'})}</h2>
							<p>
							{intl.formatMessage({id: 'solutions.personal.text'})}
							</p>

							<div className={styles.cta}>
								<span>{intl.formatMessage({id: 'solutions.personal.button.text'})}</span>
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		);
	}
}

export default injectIntl(Solutions);
